<template>
  <div id="chart-component" :style="`--background: ${background}; --shadow: ${shadow}; --border: ${border}; --tooltipBackground: ${infoTooltipBackground}`">
    <div class="wrapper">
      <div class="header">
        <div v-if="infoText" class="info-box-icon" @click="setInfoBoxVisibility(true)">
          <span class="icon">
            <img src="/images/icons/bb-icon_information.svg" />
          </span>
        </div>
        <transition
          mode="out-in"
          name="fade"
          enter-active-class="animate__animated animate__fadeInDown animate__faster"
          leave-active-class="animate__animated animate__fadeOutUp  animate__faster"
        >
          <div v-if="infoText && isInfoBoxOpened" class="info-box">
            <span class="close-icon" @click="setInfoBoxVisibility(false)">
              <i class="bb-cancel text-white"></i>
            </span>
            <div class="content-wrapper">
              <div class="icon-col">
                <span class="information-icon" />
              </div>
              <div class="content-col" v-html="infoText"></div>
            </div>
          </div>
        </transition>

        <h3 v-if="titleText" class="title-text" v-html="titleText"></h3>
        <h1 v-if="subtitleText" class="subtitle-text" v-html="subtitleText"></h1>
        <div v-if="needLegend" class="legend-text">
          <div class="legend-wrapper" v-for="(item, index) in chartData.datasets" :key="index">
            <div
              class="legend-color-item"
              :class="[item.borderDash ? 'dotted' : 'solid']"
              :style="{
                backgroundColor: item.borderDash ? 'none' : item.borderColor,
                borderTopColor: item.borderDash ? item.borderColor : 'none'
              }"
            ></div>
            <div class="legend-label-item">{{ item.label }}</div>
          </div>
        </div>
      </div>
      <div class="chart">
        <chart
          :chart-data="chartData"
          :gradientStartColor="gradientStartColor"
          :gradientEndColor="gradientEndColor"
          :labelsColor="labelsColor"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Chart from '@/components/elements/Chart.vue';

export default {
  name: 'ChartComponent',
  props: {
    titleText: {
      type: String,
      required: false,
    },
    subtitleText: {
      type: String,
      required: false,
    },
    infoText: {
      type: String,
      required: false,
    },
    chartData: {
      type: Object,
      required: true,
    },
    needLegend: {
      type: Boolean,
      required: false,
      default: false,
    },
    background: {
      type: String,
      required: false,
      default: "#fff"
    },
    shadow: {
      type: String,
      required: false,
      default: "0px 4px 8px rgba(36, 42, 85, 0.2)"
    },
    border: {
      type: String,
      required: false,
      default: "0"
    },
    gradientStartColor: {
      type: String,
      required: true
    },
    gradientEndColor: {
      type: String,
      required: true
    },
    infoTooltipBackground: {
      type: String,
      required: false,
      default: '#46668F'
    },
    labelsColor: {
      type: String,
      required: false,
      default: "#46668F" 
    }
  },
  components: {
    Chart,
  },
  data: () => ({
    isInfoBoxOpened: false,
  }),
  created() {},
  computed: {},
  methods: {
    setInfoBoxVisibility(isOpen) {
      this.isInfoBoxOpened = isOpen;
    },
  },
};
</script>

<style lang="scss" scoped>
#chart-component {
  max-width: 445px;

  @media (max-width: $breakpoint-xs) {
    max-width: none;
    width: 100vw;
  }
}

.wrapper {
  background: var(--background);
  box-shadow: var(--shadow);
  border-radius: 20px;
  position: relative;
  padding: 0 10px 30px;
  overflow: hidden;
  border: var(--border);

  @media (max-width: $breakpoint-xs) {
    border-radius: 0;
  }
}

.header {
  padding: 20px 10px 30px;
  position: relative;
  min-height: 115px;

  .title-text {
    margin: 0;
    font-size: 24px;
    line-height: 30px;
    font-family: "HKNova-Bold", sans-serif;
    font-weight: 700;
  }

  .subtitle-text {
    font-size: 16px;
    font-family: "HKNova", sans-serif;
  }

  .legend-text {
    display: flex;
    margin-top: 30px;

    .legend-wrapper {
      display: flex;
      margin-right: 30px;
      .legend-color-item {
        height: 1px;
        width: 40px;
        align-self: center;
        margin-right: 10px;
        &.dotted {
          border-top-style: dotted;
          border-top-width: 5px;
        }
        &.solid {
          height: 3px;
          border-radius: 12px;
        }
      }
      .legend-label-item {
        font-size: 14px;
        color: #96a1b2;
      }
    }
  }
}

.chart {
  & > div {
    width: 100%;
    height: 175px;

    @media (max-width: $breakpoint-md) {
      width: 330px;
    }

    @media (max-width: $breakpoint-xs) {
      width: 100%;
    }
  }
}

.info-box-icon {
  position: absolute;
  top: 22px;
  right: 23px;
  cursor: pointer;

  .icon {
    display: inline-block;
  }
}

@keyframes infoBoxFade {
  0% {
    opacity: 0;
    top: -50px;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}

.info-box {
  position: absolute;
  top: 0;
  left: -10px;
  width: calc(100% + 20px);
  height: 250px;
  background: var(--tooltipBackground);
  color: #fff;
  padding: 0 35px;
  // animation: infoBoxFade 0.5s ease-in-out;

  .content-wrapper {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: flex-start;

    .icon-col {
      display: flex;
      align-items: flex-start;

      .information-icon {
        display: inline-block;
        width: 19px;
        height: 19px;
        background: $white;
        mask-image: url("/images/icons/bb-icon_information.svg");
        mask-repeat: no-repeat;
        mask-size: cover;
        margin-right: 10px;
      }
    }
  }

  .close-icon {
    width: 35px;
    height: 35px;
    font-size: 22px;
    display: block;
    cursor: pointer;
    margin: 25px -20px 25px auto;

    img {
      filter: invert(100%);
    }
  }
}
</style>
