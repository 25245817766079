<script>
import { Line, mixins } from 'vue-chartjs';

const { reactiveProp } = mixins;

export default {
  name: 'Chart',
  mixins: [reactiveProp],
  extends: Line,
  props: {
    chartData: {
      type: Object,
      required: true, 
    },
    labelSteps: {
      type: Number,
      required: false,
      default: 1,
    },
    gradientStartColor: {
      type: String
    },
    gradientEndColor: {
      type: String,
    },
    labelsColor: {
      type: String,
      required: true
    }
  },
  components: {},
  data() {
    return {};
  },
  created() {},
  computed: {
    options() {
      return {
        hover: {
          intersect: false,
        },
        legend: {
          display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                fontSize: 16,
                fontColor: this.labelsColor,
                fontFamily: "HKNova, sans-serif",
                maxRotation: 0,
                minRotation: 0,
                userCallback: (item, index) => {
                  const steps = this.chartData.labelSteps ?? 1;
                  if (!(index % steps)) return item;
                  return null;
                },
                autoSkip: false,
                padding: 15,
              },
            },
          ],
          yAxes: [
            {
              display: false,
              gridLines: {
                display: false,
              },
              ticks: {
                display: false,
              },
            },
          ],
        },
        tooltips: {
          xPadding: 10,
          yPadding: 10,
          shadowOffsetX: 3,
          shadowOffsetY: 3,
          shadowBlur: 10,
          shadowColor: 'rgba(233, 88, 58, 0.15)',
          bodyFontSize: 16,
          backgroundColor: '#fff',
          custom: (tooltip) => {
            if (!tooltip) return;
            tooltip.displayColors = false;
          },
          callbacks: {
            label: (tooltipItem) => this.chartData.tooltipLabel(tooltipItem),
            title: () => {},
            labelTextColor: () => '#000',
          },
        },
      }
    }
  },
  methods: {},
  watch: {
    chartData: {
      handler() {
        if (this.chartData) {
          this.renderChart(this.chartData, this.options);
        }
      },
    },
  },
  mounted() {
    let ctx = this.$refs.canvas.getContext("2d");
    let gradient = ctx.createLinearGradient(0, 0, 0, 140);
    gradient.addColorStop(0, this.gradientStartColor);   
    gradient.addColorStop(1, this.gradientEndColor);

    this.chartData.datasets[0].backgroundColor = gradient;
    
    this.renderChart(this.chartData, this.options);
  },
};
</script>

<style lang="scss" scoped>
</style>
